
import React from "react";
import Switch from "react-bootstrap-switch";
import Slider from "nouislider";
import wNumb from 'wnumb';

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function CustomerInfoForm({passTotal = () => {}, price = 999}) {
  React.useEffect(() => {
    if (
      !document
        .getElementById("sliderRegular")
        .classList.contains("noUi-target")
    ) {
      Slider.create(document.getElementById("sliderRegular"), {
        start: [1],
        connect: [true, false],
        step: 1,
        tooltips: true,
        format: wNumb({
            decimals: 0
        }),
        range: { min: 0, max: 10 },
        pips: {
            mode: 'steps',
            stepped: true,
            density : 10
        },
        
      });
      document.getElementById("sliderRegular").noUiSlider.on('change',(e)=>passTotal(Number(...e)*price))
    }
  },[]);

  return (
    <>
      <div className="py-3">
          
          <Row>
            <Col md="12" lg="12" sm="3">
              <FormGroup>
                <Label >Primay Traveller's Full Name<sup className="mandatory-field">*</sup></Label>
                <Input placeholder="Full Name" type="text" />
              </FormGroup>
            </Col>
            </Row>

            <Row>
            <Col md="12" lg="12" sm="3">
              <FormGroup>
                <Label >Email<sup className="mandatory-field">*</sup></Label>
                <Input placeholder="Email" type="email" />
              </FormGroup>
            </Col>
          </Row>

            <Row>
                <Col md="6" lg="6" sm="3">
                    <FormGroup>
                        <Label >Phone Number<sup className="mandatory-field">*</sup></Label>
                        <Input placeholder="Phone Number" type="text" />
                    </FormGroup>
                </Col>
                <Col md="6" lg="6" sm="3">
                    <FormGroup>
                        <Label >Alt Phone Number</Label>
                        <Input placeholder="Alt Phone Number" type="text" />
                    </FormGroup>
                </Col>
            </Row>
          <br />
          <Row>
            <Col lg="12" sm="6">
                <Label >Number of Travellers</Label>
              <div className="slider" id="sliderRegular" />
              <br />
            </Col>
          </Row>
        
      </div>
    </>
  );
}

export default CustomerInfoForm;
