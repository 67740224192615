
import React from "react";
import ReactDatetime from "react-datetime";

import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";
import ModalComponent from "../../components/Core/ModalComponent.js";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import CustomerInfoForm from "components/Forms/CustomerInfoForm.js";

function ProfilePage() {
  const [activeTab, setActiveTab] = React.useState("1");
  const [isOpen, setOpen] = React.useState(false);
  const [total, setTotal] = React.useState(999);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  const onClickOpenPayment = () => setOpen(true);

  const onClickClosePayment = () => setOpen(false);

  const passTotal = (calculatedTotal = 0) => setTotal(calculatedTotal);

  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <br />
            <div className="name">
              <h4 className="title">
                <b>Raigad Fort Trekking</b> <br />
              </h4>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                <FormGroup style={{width:'40%'}} className="mx-auto">
                <InputGroup className="date" id="datetimepicker">
                  <ReactDatetime
                    style= {{width: '60px'}}
                    defaultValue={"20-04-2020"} 
                    inputProps={{
                      disabled: true,
                      placeholder: "Datetime Picker Here",
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <span className="glyphicon glyphicon-calendar">
                        <i aria-hidden={true} className="fa fa-calendar" />
                      </span>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
                </Col>
              </Row>  
              <Row>
              <Col className="ml-auto mr-auto text-center" md="6">

              <Button
                    className="btn-round mr-1 my-4"
                    color="success"
                    type="button"
                    size="lg"
                    onClick={onClickOpenPayment}
                  >Book for {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format('999')}<sup>*</sup> per person</Button>
                  </Col>
            </Row>            
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <p>
              Trekking enthusiasts, this holiday just got more exciting for you! Head for a fun and exciting one day trek to Raigad in Maharashtra with your friends and experience pure adventure!<br /> This is a hill fortress situated in the modern day near Mahad, Raigad district of Maharashtra, India. 
              The fort, which rises 820 metres (2,700 ft) above sea level, is located in the Sahyadri mountain range. There are approximately 1400–1450 steps leading to the fort, though today a rope-way exists to reach the top of the fort. 
              <br />Get fascinated by the amazing stories that you hear about this fort and all that it went through during the war with the British!
              </p>
              <br />
              {/* <Button className="btn-round" color="default" outline>
                <i className="fa fa-cog" /> Settings
              </Button> */}
            </Col>
          </Row>
          <br />
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Itenerary
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Inclusions
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "3" ? "active" : ""}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Exclusions
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          {/* Tab panes */}
          <TabContent className="following" activeTab={activeTab}>
            <TabPane tabId="1" id="itenarary">
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <ul className="itenarary">
                    <li className="mb-3">
                      <p>05:00 AM - Leave from your city to reach Pachad village by 9:30 AM</p>
                    </li>
                    <li className="mb-3">
                      <p>09:30 AM - Reach Pachad village, indulge in local breakfast, and start the trek</p>
                    </li>
                    <li className="mb-3">
                      <p>12:01 PM - Reach the top, explore the fort, have lunch</p>
                    </li>
                    <li className="mb-3">
                      <p>01:30 PM - Start moving towards the base</p>
                    </li>
                    <li className="mb-3">
                      <p>03:00 PM - Reach the base and move towards your city</p>
                    </li>
                  </ul>
                  <hr />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2" id="inclusions">
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <ul className="itenarary">
                    <li className="mb-3">
                      <p>Breakfast</p>
                    </li>
                    <li className="mb-3">
                      <p>Lunch</p>
                    </li>
                    <li className="mb-3">
                      <p>Mineral Water</p>
                    </li>
                    <li className="mb-3">
                      <p>Trek expertise and entry charges</p>
                    </li>
                    <li className="mb-3">
                      <p>Transport by non A/C private vehicle</p>
                    </li>
                  </ul> 
                </Col>
              </Row>        
            </TabPane>
            <TabPane tabId="3" id="exclusions">
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <ul className="itenarary">
                    <li className="mb-3">
                      <p>Anything <b>not</b> metioned in the 'Inclusions'</p>
                    </li>
                  </ul>
                </Col>
              </Row>
                              
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <ModalComponent onClose={onClickClosePayment} isOpen={isOpen} title="Basic Information" total={total}><CustomerInfoForm passTotal={passTotal}></CustomerInfoForm></ModalComponent>
      <DemoFooter />
    </>
  );
}

export default ProfilePage;
