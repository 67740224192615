
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        {/* <Row>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by <a
                  href="https://www.linkedin.com/in/abhinand-shetty-963847107/"
                  target="_blank"
                >
                  Abhinand Shetty
                </a>
            </span>
          </div>
        </Row> */}
      </Container>
    </footer>
  );
}

export default DemoFooter;
