
import React from "react";

import {
  Button,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover
} from "reactstrap";


function ModalComponent({isOpen, onClose =()=>{}, title="", children, total = 0}) {
  
  return (
    <>
        <Modal isOpen={isOpen} size="md">
            <div className="modal-header">
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={onClose}
                >
                <span aria-hidden={true}>×</span>
                </button>
                <h5
                className="modal-title text-center"
                id="exampleModalLabel"
                >
                    {title}
                </h5>
            </div>
            <div className="modal-body">
                {children}
            </div>
            <div className="modal-footer text-center bg-success">
                <Button
                    className="btn-link"
                    type="button"
                    onClick={onClose}
                >
                    Proceed to pay {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(total)}
                </Button>
            </div>
        </Modal>
    </>
  );
}

export default ModalComponent;
