
import React from "react";
import { Container, Row, Col } from "reactstrap";


function SectionEvents() {
  return (
    <>
        <div className="section pt-o" id="events">
            <Container className="container events-container">
                <div className="title">
                    <h2>Upcoming Events</h2>
                </div>
                <Container>  
                    <Row>
                        <Col md="3" sm="6" className="mb-2 event-block">
                        <a href="/events">

                        <img
                            alt="..."
                            className="img-rounded img-responsive"
                            src={require("assets/img/raigad.jpg")}
                        />
                        <p className="mt-2 event-title">Trip to Raigad</p>
                        <p>16-04-2020 to 17-04-2020</p>
                        </a>
                        </Col>
                        
                
                        <Col md="3" sm="6" className="mb-2 event-block">
                        <a href="/events">

                        <img
                            alt="..."
                            className="img-rounded img-responsive"
                            src={require("assets/img/sinhagad.jpg")}
                        />
                        <p className="mt-2 event-title">Trip to Sinhagad</p>
                        <p>27-04-2020 to 28-04-2020</p>
                        </a>
                        </Col>
                
                        <Col md="3" sm="6" className="mb-2 event-block">
                        <a href="/events">

                        <img
                            alt="..."
                            className="img-rounded img-responsive"
                            src={require("assets/img/pawna.jpg")}
                        />
                        <p className="mt-2 event-title">Pavana Camping</p>
                        <p>02-05-2020 to 02-05-2020</p>
                        </a>
                        </Col>
                        <Col md="3" sm="6" className="mb-2 event-block">
                        <a href="/events">

                        <img
                            alt="..."
                            className="img-rounded img-responsive"
                            src={require("assets/img/devkund.jpg")}
                        />
                        <p className="mt-2 event-title">Devkund Waterfall Trip</p>
                        <p>20-06-2020 to 20-06-2020</p>
                        </a>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    </>
  );
}

export default SectionEvents;
