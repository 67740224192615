
import React from "react";

// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

// core components

function SectionStatistics() {
  return (
    <>
      <div className="section section-navigation bg-success">
        
      </div>
    </>
  );
}

export default SectionStatistics;
